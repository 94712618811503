import base from './base';

export default {
  isEmpty(conf) {
    const c = base.extend({
      type: 'text',
      val: '',
    }, conf);

    let res = false;
    const v = c.val;

    switch (c.type) {
      case 'text':
        if (v === '' || v === null) {
          res = true;
        }
        break;
      default:
    } // end switch: type

    return res;
  }, // end isEmpty

  isIP(conf) {
    const c = base.extend({
      val: '',
    }, conf);

    const reg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    return reg.test(c.val);
  }, // end isIP

  isURL(conf) {
    const c = base.extend({
      val: '',
    }, conf);

    // eslint-disable-next-line
    const reg = /^(((?:(?:https?|ftp):\/\/)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?(:([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?)$/;

    return reg.test(c.val);
  },

  isDomain(conf) {
    const c = base.extend({
      val: '',
    }, conf);
    const reg = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/;

    return reg.test(c.val);
  },

  isAccount(conf) {
    const c = base.extend({
      val: '',
    }, conf);

    const reg = /^[a-zA-Z0-9_@.]+$/;

    return reg.test(c.val);
  }, // end isAccount

  isPwd(conf) {
    const c = base.extend({
      val: '',
    }, conf);

    const reg = /^[a-zA-Z0-9]+$/;

    return reg.test(c.val);
  }, // end isPwd

  isEmail(conf) {
    const c = base.extend({
      val: '',
    }, conf);

    const reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    return reg.test(c.val);
  }, // end isEmail

  isCSV(conf) {
    const c = base.extend({
      val: '',
    }, conf);

    let res = false;
    const v = c.val;

    if (v instanceof File && (v.type === 'text/csv' || v.type === 'application/vnd.ms-excel')) {
      res = true;
    }

    return res;
  }, // end isCSV

  isSizeLimit(conf) {
    const c = base.extend({
      val: '',
    }, conf);

    let res = false;
    const v = c.val;
    const limit = 1 << 20; // eslint-disable-line no-bitwise

    if (v.size > limit) { // size larger than 1 MB
      res = true;
    }

    return res;
  }, // end isSizeLimit
};
// end: verify
