<template>
  <div class="subscribe">
    <Header />
    <div class="content-block">
      <div v-if="!subscribed" class="service-block">
        <b-form @submit="submitSubscribe">
          <ol>
            <li>
              <p>服務適用對象：資安人員、資訊系統管理員。</p>
            </li>

            <li>
              <p>注意事項：</p>
              <ul>
                <li>
                  <p>本服務旨在提供資安人員常見漏洞與披露（Common Vulnerabilities and Exposures，CVE）相關分析報告與驗證用測試程式。</p>
                </li>
                <li>
                  <p>本服務所提供測試程式旨在協助資安人員驗證目標系統是否含有特定CVE漏洞，測試過程可能會對目標系統造成損害，在此明確表示，
                    <b>使用者需自行評估可行性並承擔有造成系統毀損、資料損壞或資料遺失或風險</b>。</p>
                </li>
                <li>
                  <p>本服務並未設計提供或在未經許可狀況下，於危險環境或其他故障可能導致財產損失或人身傷害的情況下應用本服務，
                    特別聲明本服務將不會為申請者承擔任何法律責任，<b>申請者應自行承擔與使用服務相關的所有風險</b>。</p>
                </li>
                <li>
                  <p><b>本網站保有暫停、終止或拒絕您使用本服務之權力</b>。</p>
                </li>
              </ul>
            </li>

            <li>
              <p>情資訂閱：</p>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <b-form-input
                    id="input-email"
                    v-model="email"
                    type="email"
                    placeholder="請輸入您的 E-mail"
                    required
                    :state="emailState"
                    @key.enter="submitSubscribe"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-email">
                    {{Object.keys(err).length > 0 && err.hasOwnProperty('email')? err.email: ''}}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </li>

            <li class="list-type-none">
              <b-form-checkbox
                v-model="serviceCheck"
              >
                我已閱讀並了解服務限制與聲明，且同意接受及遵守服務使用條款所述之內容。
              </b-form-checkbox>
            </li>

            <li class="list-type-none text-center">
              <b-button variant="outline-primary"
                :disabled="!serviceCheck"
                @click.prevent="submitSubscribe"
               >
                訂閱
              </b-button>
            </li>
          </ol>
        </b-form>
      </div>
      <!-- end: service block -->
      <div v-else class="service-block">
        <div class="subscribed py-3">
          <h1>情資訂閱</h1>
          <b-icon class="my-3" icon="check-circle" font-scale="7.5" variant="success"></b-icon>
          <p class="description">訂閱完成</p>
          <p class="text">您已完成情資訂閱，訂閱之電子郵件地址為： {{subscribedEmail}} <br>
          如需更改電子郵件地址，請聯絡系統管理員： TZWang@itri.org.tw</p>
          <b-button to="/main" variant="outline-secondary">返回首頁
             <b-icon icon="arrow-left-circle" font-scale="1.1"/></b-button>
        </div>
      </div>
    </div>
    <!-- end: content block -->
  </div>
</template>

<script>
import Header from '@/layout/Header.vue';
import Cookie from 'js-cookie';

export default {
  name: 'Subscribe',
  components: {
    Header,
  },
  created() {
    try {
      const userInfoCookie = Cookie.get('user_info');
      const userInfo = JSON.parse(userInfoCookie);
      if (Object.prototype.hasOwnProperty.call(userInfo, 'email')
         && userInfo.email !== '') {
        this.subscribed = true;
        this.subscribedEmail = userInfo.email;
      }
    } catch (error) {
      this.subscribed = false;
    }
  },
  data() {
    return {
      email: '',
      serviceCheck: false,
      err: {},
      emailFilled: false,
      subscribed: false,
      subscribedEmail: '',
    };
  },
  computed: {
    emailState() {
      if (!this.emailFilled) return null;
      this.verifySubscribe();
      return !(Object.keys(this.err).length > 0 && Object.prototype.hasOwnProperty.call(this.err, 'email'));
    },
  },
  methods: {
    resetForm() {
      const _this = this;

      _this.email = '';
      _this.err = {};
      _this.emailFilled = false;
    }, // end resetForm
    verifySubscribe() {
      const _this = this;

      // verify: email
      if (_this.$verify.isEmpty({
        val: _this.email,
      })) {
        _this.$set(_this.err, 'email', '請輸入 Email');
      } else if (!_this.$verify.isEmail({
        val: _this.email,
      })) {
        _this.$set(_this.err, 'email', '格式錯誤：請輸入 xxx@xxx.xxx');
      } else {
        _this.$delete(_this.err, 'email');
      }
    }, // end verifySubscribe
    submitSubscribe() {
      const _this = this;
      _this.verifySubscribe();

      if (Object.keys(_this.err).length === 0) {
        _this.$base.ajaxFunc({
          method: 'post',
          url: '/ssc/subscribe',
          data: {
            email: _this.email,
          },
          success() {
            _this.resetForm();

            _this.$store.dispatch('alert', {
              vm: _this,
              type: 'success',
              msg: '已成功訂閱！',
              callback() {
                _this.$router.push({ path: '/main' });
              },
              callbackTimeout: 2000,
            });
          }, // end success
          vm: _this,
        }); // end call: ajaxFunc
      } // end if: no error
      this.emailFilled = true;
    }, // end submitSubscribe
  }, // end methods
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.service-block {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: $main-color-white;
  border-radius: 10px;

  @media all and (max-width: 1024px) {
      max-width: 920px;
  }

  li {
    margin-bottom: 1rem;

    &.list-type-none {
      list-style-type: none;
    }

    p {
      margin-bottom: 0;

      & + * {
        margin-top: 1rem;
      }
    } // end p
  } // end li

  ::v-deep .custom-control-label {
    cursor: pointer;
  }

  ::v-deep .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    color: $note-color;
    border-color: $note-color;
  }

  .subscribed {
    text-align: center;
    .description {
      font-size: 2rem;
      color: $green;
      font-weight: bold;
    }
    .text {
      color: $note-color;
    }
  }
} // end service block
</style>
