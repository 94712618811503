<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
#app .header + .content-block {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow: hidden auto;
  background-color: $bg-color-grey;
  padding: 40px 20px;
}
@media all and (max-width: 767px) {
  #app .header + .content-block {
    padding: 20px 10px;
  }
} // end <= 767
</style>
