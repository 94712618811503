<template>
  <div v-if="dataLoaded" class="metering">
    <Header />
    <div class="content-block">
      <div class="summary block">
        <b-container>
          <b-row>
            <b-col xs="auto" md="4">
              <div class="summary-score" v-if="summaryScore>=0">{{summaryScore}}</div>
              <div class="summary-score" v-else>- -</div>
            </b-col>
            <b-col class="company-info d-md-flex align-items-center">
                <div class="text-center text-md-left w-100">
                  <h1>資安評級</h1>
                  <hr>
                  <p class="domain">
                    <span class="info-label">DOMAIN: </span>
                    {{summaryDomain}}
                  </p>
                  <p>
                    <span class="info-label">檢測時間: </span>
                      {{ createdDate ? $datetimeFormat(new Date(createdDate), 'YYYY-MM-DD HH:mm:ss')
                       : '' }}
                  </p>
                </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="report block">
        <div class="content details" v-if="summaryScore>=0&&details.length>0">
          <p class="report-heading">發現漏洞</p>
          <b-list-group>
            <b-list-group-item
              class="list-item"
              v-for="(detail, detailIndex) in details"
              :key="detailIndex"
              button
              @click.prevent="openDetailModal(detail)"
            >
              <span class="cvss-score" :class="getScoreVariant(detail.cvss)">
                {{detail.cvss.toFixed(1)}}
              </span>
              <span class="vul-name">
                {{detail.name}}
              </span>
              <b-badge class="vul-count" variant="secondary" pill>
                {{detail.counts}}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </div>
        <!-- if no details -->
        <div class="no-report"
            v-else-if="summaryScore>=0&&details.length===0&&detailsFetchSuccess">
          <b-icon class="my-3" icon="check-circle" font-scale="7.5" variant="success"></b-icon>
          <p class="description success">未發現漏洞</p>
        </div>
        <!-- if testing is still under execution -->
        <div class="no-report" v-else-if="summaryScore===-1">
          <b-icon class="my-3" icon="hourglass-split" font-scale="7.5" variant="secondary"></b-icon>
          <p class="description">評估正在進行中 ...</p>
          <p class="inquiry">評估時間約需1至3個工作日，若有疑問請洽：<br>TSWang@itri.org.tw</p>
        </div>
        <!-- if testing failed -->
        <div class="no-report" v-else-if="summaryScore===-2">
          <b-icon class="my-3" icon="exclamation-circle" font-scale="7.5" variant="danger"></b-icon>
          <p class="description error">評估失敗</p>
          <p class="inquiry">系統在進行評估時發生了問題，請洽服務管理員以了解詳情：<br>TSWang@itri.org.tw</p>
        </div>
        <!-- catch all other errors -->
        <div class="no-report"
            v-else>
          <b-icon class="my-3" icon="exclamation-circle" font-scale="7.5" variant="danger"></b-icon>
          <p class="description error">尚無資料</p>
          <p class="inquiry">未能成功獲取資料，請洽服務管理員以了解詳情：<br>TSWang@itri.org.tw</p>
        </div>
      </div>
    </div>
    <!-- end: content block -->
    <!-- modal for showing vulnerabilities detail -->
    <b-modal id="detail-modal" size="lg" centered
      header-class="header-styling" footer-class="footer-styling">
      <template #modal-header>
          <!-- <div class="px-3 pt-3"> -->
            <h1 class="modal-title">
              <span
            :class="getScoreVariant(detailModalContent.cvss)"
            class="modal-cvss-score">
            {{detailModalContent.cvss.toFixed(1)}}
            </span>
            <span class="modal-vul-name">{{detailModalContent.name}}</span>
            </h1>
            <b-badge variant="secondary" class="modal-vul-count">
              漏洞揭發次數：{{detailModalContent.counts}}
            </b-badge>
          <!-- </div> -->
        </template>
        <div class="modal-content-body">
        <div class="pb-3">
          <h2 class="modal-subheading">漏洞說明</h2>
          <p>{{detailModalContent.description}}</p>
        </div>
        <div class="py-3">
          <h2 class="modal-subheading">修補建議</h2>
          <p>{{detailModalContent.solution}}</p>
        </div>
        <div class="pt-3">
          <h2 class="modal-subheading">IP列表</h2>
          <ul>
            <li v-for="(host,idx) in detailModalContent.hosts" :key="idx">{{host}}</li>
          </ul>
        </div>
      </div>

      <template #modal-footer="{ ok }">
        <b-button variant="outline-secondary" @click="ok()" pill>
          回到前頁
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Header from '@/layout/Header.vue';
import Cookies from 'js-cookie';

export default {
  name: 'Metering',
  components: {
    Header,
  },
  data() {
    return {
      summaryScore: -3,
      requestDomain: '', // for request
      createdDate: '',
      summaryDomain: 'no domain info',
      sscID: 0,
      details: [],
      detailModalContent: {
        cvss: 0,
        name: '',
        counts: 0,
        description: '',
        solution: '',
        hosts: [],
      },
      dataLoaded: false,
      detailsFetchSuccess: false,
    };
  },
  computed: {
  },
  async created() {
    try {
      const userInfo = JSON.parse(Cookies.get('user_info'));
      if (Object.prototype.hasOwnProperty.call(userInfo, 'domain')
        && userInfo.domain !== '') {
        this.requestDomain = userInfo.domain;
        await this.getSSC();
        if (this.summaryScore > 0) await this.getSSCDetails();
        this.dataLoaded = true;
      } else {
        this.$router.push('/register');
      }
    } catch (error) {
      console.warn(error);
      const _this = this;
      this.$store.dispatch('alert', {
        vm: this,
        type: 'danger',
        msg: '未能獲取資料，請聯絡系統管理',
        callback() {
          _this.$router.push('/main');
        },
      });
    }
  },
  mounted() {
  },
  methods: {
    getScoreVariant(cvssScore) {
      const score = Number(cvssScore);
      if (score === 0) return '';
      if (score < 4) return 'low';
      if (score < 7) return 'medium';
      if (score < 9) return 'high';
      return 'critical';
    },
    async getSSC() {
      let rows = [];
      const _this = this;
      await this.$base.ajaxFunc({
        needAwait: true,
        method: 'get',
        url: `/ssc/summary/${this.requestDomain}`,
        params: {},
        success(res) {
          if (Object.prototype.hasOwnProperty.call(res, 'rows') && res.rows.length >= 0) {
            rows = res.rows;
          }
        },
        vm: _this,
      });
      if (rows.length > 0) {
        let validIndex = 0;
        let latestRunningIdx = -1;
        for (let i = 0; i < rows.length; i += 1) {
          if (rows[i].score >= 0) {
            validIndex = i;
            break;
          }
          if (rows[i].score === -1 && latestRunningIdx === -1) {
            latestRunningIdx = i;
          }
        }
        if (rows[validIndex].score === -2 && latestRunningIdx !== -1) {
          validIndex = latestRunningIdx;
        }
        const newestReport = rows[validIndex];
        this.sscID = newestReport.id;
        this.createdDate = newestReport.createdAt;
        this.summaryScore = newestReport.score;
        this.summaryDomain = newestReport.domain;
      }
    },
    async getSSCDetails() {
      let tmpDetails = [];
      const _this = this;
      await this.$base.ajaxFunc({
        needAwait: true,
        method: 'get',
        url: `/ssc/details/${this.sscID}`,
        params: {},
        success(res) {
          if (Object.prototype.hasOwnProperty.call(res, 'rows') && res.rows.length >= 0) {
            tmpDetails = res.rows;
          }
          _this.detailsFetchSuccess = true;
        },
        vm: this,
      });
      tmpDetails.sort((a, b) => (b.cvss - a.cvss));
      this.details = tmpDetails;
    },
    openDetailModal(detail) {
      this.detailModalContent.cvss = detail.cvss || 0;
      this.detailModalContent.name = detail.name || '';
      this.detailModalContent.counts = detail.counts || 0;
      this.detailModalContent.description = detail.description || '暫無描述';
      this.detailModalContent.solution = detail.solution || '暫無修補建議';
      this.detailModalContent.hosts = detail.hosts || [];
      this.$bvModal.show('detail-modal');
    },
  }, // end methods
};
</script>

<style scoped lang="scss">

.block {
  background-color: $main-color-white;
  width: 80%;
  margin: 0 auto 1rem auto;
  padding: 1rem;
  border-radius: 10px;
}

.summary {
  .summary-score {
    display: block;
    text-align: center;
    color: $main-color;
    font-size: 10rem;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    letter-spacing: 5px;
    margin: 0 auto;
  }
  .company-info {
    padding: 0 2rem;
  }
  .info-label {
    color: $dark-color-grey;
    font-weight: bold;
  }
  h1 {
    font-size: 2.5em;
  }
  p {
    margin: 0;
  }
}

.report {
  padding: 2rem;
  .report-heading {
    color: $dark-color-grey;
    font-size: 2rem;
    font-weight: bold;
  }
  .content {
    width: 90%;
    margin: 0 auto;
  }
  .list-item {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 1rem;
    }
    .cvss-score {
      font-size: 1.4rem;
      font-weight: bold;
      margin-right:1rem;
      width: 3rem;
      text-align: right;
    }
    .vul-name {
      overflow: hidden;
      height: 1.5rem;
    }
    .vul-count {
      margin-left: auto;
    }
  }

  .no-report {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .description {
      color: $dark-color-grey;
      font-size: 2rem;
      font-weight: bold;
    }
    .error {
      color: $red;
    }
    .success {
      color: $green;
    }
    .inquiry {
      color: $note-color;
      text-align: center;
      font-size: 1rem;
    }
  }
}

// variant colors
.critical {
  color: $red;
}

.high {
  color: $orange;
}

.medium {
  color: $yellow;
}

.low {
  color: $green;
}

#detail-modal {
  .modal-title {
    font-size: 2rem;
    .modal-cvss-score {
      font-weight: bold;
    }
  }
  .modal-vul-count {
    font-size: 1.1rem;
  }
  .modal-subheading {
    font-size: 1.5rem;
  }
}

::v-deep {
  .header-styling {
    display: block;
    border-bottom: none;
  }
  .footer-styling {
    padding: 1rem;
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
    .block {
      width: 100%;
    }
    #app .header + .content-block {
      padding: 2rem;
    }

    .company-info {
      margin-bottom: 2rem;
    }
    .info-label {
      display: block;
    }
}

</style>
