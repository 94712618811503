import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
// import Main from '../views/Main.vue';
import PTTool from '../views/PTTool.vue';
import Subscribe from '../views/Subscribe.vue';
import UnSubscribe from '../views/UnSubscribe.vue';
import NotFound from '../views/NotFound.vue';
import Metering from '../views/Metering.vue';
import RegisterDomain from '../views/RegisterDomain.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    alias: ['/login'],
    name: '登入',
    component: Login,
    meta: { isRootPath: true },
  },
  {
    path: '/main',
    name: '功能清單',
    component: PTTool,
    meta: { requiresAuth: true },
  },
  {
    path: '/pttool',
    name: '滲透測試工具',
    component: PTTool,
    meta: { requiresAuth: true },
  },
  {
    path: '/intelligence',
    name: '訂閱情資',
    component: Subscribe,
    meta: { requiresAuth: true },
  },
  {
    path: '/unsubscribe',
    name: '取消訂閱',
    component: UnSubscribe,
  },
  {
    path: '/metering',
    name: '評級資訊',
    component: Metering,
    meta: { requiresAuth: true },
  },
  {
    path: '/register',
    name: '註冊網域',
    component: RegisterDomain,
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
