import Vue from 'vue';
import { BootstrapVue, IconsPlugin, BVToastPlugin } from 'bootstrap-vue';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';
import Cookies from 'js-cookie';

import App from './App.vue';
import router from './router';

// customize
import base from './customize/base';
import verify from './customize/verify';
import store from './customize/store'; // 引入狀態管理 store

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(BVToastPlugin);

// datetime format
const datetimeFormat = (date, format = 'YYYY-MM-DD') => (date ? dayjs(date).locale('zh-tw').format(format) : '');
Vue.use(datetimeFormat);
Vue.prototype.$datetimeFormat = datetimeFormat;

// customize js
Vue.prototype.$base = base;
Vue.prototype.$verify = verify;
Vue.prototype.$store = store;

// 路由登入驗證
router.beforeEach((to, from, next) => {
  // console.warn('to', to);
  // console.warn('from', from);

  if (to.meta.requiresAuth) { // check if the target page requires auth
    if (Cookies.get('ITRI_PT_Sessions')) { // check if browser authenticated, if yes, continue
      next();
    } else { // otherwise, redirect to login page
      next({
        path: '/login',
        query: { redirect: to.fullPath }, // let target be the query param, redirect after login
      });
    }
  } else if (to.meta.isRootPath) { // for root and login page
    // if already login, redirect to main
    if (Cookies.get('ITRI_PT_Sessions')) {
      next({
        path: '/main',
      });
    } else {
      next(); // otherwise goto login page
    }
  } else {
    next(); // otherwise goes to target page
  }
}); // end router auth

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
