<template>
  <div class="header">
<!--
    <div class="menu-block">
      <b-button v-b-toggle.menu variant="outline-secondary" size="sm" class="menu-btn">
        <b-icon icon="justify"></b-icon>
      </b-button>
      <b-sidebar id="menu" title="Menu" shadow>
        <div class="menu-content-block">
          <b-list-group>
            <b-list-group-item to="/metering">資安評級</b-list-group-item>
            <b-list-group-item to="/intelligence">訂閱情資</b-list-group-item>
            <b-list-group-item to="/pttool">滲透測試工具</b-list-group-item>
          </b-list-group>
        </div>
      </b-sidebar>
    </div>
-->

    <div class="product-txt">
      <div>
        <b-link to="/main" class="product-link">
        <img src="../assets/imgs/scorpione-white.png" alt="scorpione-logo"/>
        <span>SCORPIONE</span>
        </b-link>
      </div>
    </div>

    <div class="action-block">
      <div>
        <div class="username d-none d-sm-block">
        {{username}}
        </div>
        <b-button variant="link" @click="logout">
          <b-icon icon="box-arrow-right"></b-icon> 登出
        </b-button>
      </div>
    </div>
    <!-- end: action block -->
  </div>
</template>

<script>
import Cookie from 'js-cookie';

export default {
  name: 'Header',
  methods: {
    logout() {
      this.$store.dispatch('userSignOut');
    },
  },
  created() {
    try {
      const userInfo = JSON.parse(Cookie.get('user_info'));
      this.username = userInfo.name;
    } catch (error) {
      // console.log(userInfo);
      this.username = '';
    }
  },
  data() {
    return {
      username: '',
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  background-color: $main-color;
  min-height: 60px;
  padding: 15px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    flex: 1;
  }

  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
  z-index: 11;

  .menu-block {
    display: flex;
    align-items: center;
    .menu-btn {
      border-color: #e5e5e5;
      color: #e5e5e5;

      &:hover,
      &:active {
        background-color: $main-color-white !important;
        color: $note-color !important;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgb(229 229 229 / 50%);
      }
    }
  }

  .product-txt {
    display: flex;
    align-items: center;
    > div {
      margin: 0 auto;
    }
    a {
      margin: 0;
      color: $main-color-white !important;
      font-size: 1.5rem;
      font-weight: bold;
      text-decoration: none;
    }
    img {
      height: 2.5rem;
      padding-right: 5px;
    }
  }

  .action-block {
    display: flex;
    color: $main-color-white;
    .btn {
      color: $main-color-white;
      padding: 0;
      width: max-content;
    }
    .username {
      margin-right: 0.5rem;
    }
    > div {
      display: flex;
      margin-left: auto;
    }
  } // end action block

} // end header

@media screen and (max-width: 767px) {
  .header {
    padding-left: 20px;
    padding-right: 20px;
    max-height: 100px;
    overflow: hidden;
    .product-txt {
      a {
        font-size: 1rem;
        display: flex;
        align-items: center;
      }
      img {
        height: 2rem;
      }
    }
  }
}
// end media: width < 768
</style>
