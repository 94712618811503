import axios from 'axios';

const $http = axios.create({
  baseURL: '/',
  timeout: 7000,
  headers: {},
});

window.Clipboard = (function (window, document, navigator) {
  let textArea;

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text) {
    textArea = document.createElement('textArea');
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range;
    let selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand('Copy');
    document.body.removeChild(textArea);
  }

  const copy = function (text) {
    createTextArea(text);
    selectText();
    copyToClipboard();
  };

  return {
    copy,
  };
}(window, document, navigator));

const _extend = function (...args) {
  // Variables
  const extended = {};
  let deep = true;
  let i = 0;
  const len = args.length;

  // console.warn('_extend args', args);

  // Check if a deep merge
  if (Object.prototype.toString.call(args[0]) === '[object Boolean]') {
    [deep] = args;
    i += 1;
  }

  // Merge the object into the extended object
  const _merge = function (obj) {
    // console.warn('_merge obj', obj);

    Object.keys(obj).forEach((prop) => {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        // If deep merge and property is an object, merge properties
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          extended[prop] = {};
          extended[prop] = _extend(true, extended[prop], obj[prop]);
        } else {
          extended[prop] = obj[prop];
        }
      }
    });
  };

  // Loop through each object and conduct a merge
  for (; i < len; i += 1) {
    const obj = args[i];
    _merge(obj);
  }

  return extended;
}; // end: _extend

// const _getMeta = function (metaName) {
//   const metas = document.getElementsByTagName('meta');
//
//   for (let i = 0; i < metas.length; i += 1) {
//     if (metas[i].getAttribute('name') === metaName) {
//       return metas[i].getAttribute('content');
//     }
//   }
//
//   return '';
// }; // end _getMeta function

function _paddingLeft(str, len) {
  const s = `${str}`;
  if (typeof len === 'undefined') return '';

  if (s.length >= len) return s;

  return _paddingLeft(`0${s}`, len);
} // end _paddingLeft

function handleError(res, vm, signOut = false) {
  let callbackFunc = () => {};
  if (signOut === true) {
    callbackFunc = () => {
      vm.$store.dispatch('userSignOut');
    };
  }
  if (Object.prototype.hasOwnProperty.call(res, 'msg')) {
    vm.$store.dispatch('alert', {
      vm,
      type: 'danger',
      msg: res.msg,
      callback: callbackFunc,
    });
  }
}

export default {
  extend: _extend,

  getValuesByKey(object, key) {
    const values = [];
    JSON.stringify(object, (k, v) => {
      if (k === key) values.push(v);
      return v;
    });
    return values;
  },

  async ajaxFunc(conf) {
    // console.warn('ajaxFunc conf', conf);
    const c = {
      needAwait: Object.prototype.hasOwnProperty.call(conf, 'needAwait') ? conf.needAwait : false,
      method: Object.prototype.hasOwnProperty.call(conf, 'method') ? conf.method : 'post',
      url: Object.prototype.hasOwnProperty.call(conf, 'url') ? conf.url : '',
      data: Object.prototype.hasOwnProperty.call(conf, 'data') ? conf.data : {},
      params: Object.prototype.hasOwnProperty.call(conf, 'params') ? conf.params : {},
      headers: Object.prototype.hasOwnProperty.call(conf, 'headers') ? conf.headers : {},
      success: Object.prototype.hasOwnProperty.call(conf, 'success') ? conf.success : () => {},
      error: Object.prototype.hasOwnProperty.call(conf, 'error') ? conf.error : handleError,
      vm: Object.prototype.hasOwnProperty.call(conf, 'vm') ? conf.vm : null,
    };
    if (process.env.NODE_ENV === 'development') console.warn('ajaxFunc conf', c);

    if (c.url === '') {
      console.log('AJAX Error: url is empty.');
      return false;
    }

    // const csrf = _getMeta('csrf-token');

    const _conf = {
      method: c.method,
      url: c.url,
      data: c.data,
      params: c.params,
      headers: c.headers,
    };

    function _then(res) {
      // if (process.env.NODE_ENV === 'development') console.warn('Ajax then res', res);

      if (Object.prototype.hasOwnProperty.call(res, 'data') && Object.keys(res.data).length >= 0) {
        const resData = res.data;
        c.success(resData);
      } else {
        c.error({
          msg: '請稍後再試！<程式錯誤訊息: response is empty.>',
        }, c.vm);
      }
    } // end _then function

    function _catch(res) {
      if (process.env.NODE_ENV === 'development') console.log('node catch', res);
      let signOut = false;
      if (typeof res !== 'undefined' && Object.prototype.hasOwnProperty.call(res, 'data')
       && Object.keys(res.data).length > 0) {
        const resData = res.data;
        const resStatusCode = res.status;
        if (resStatusCode === 401) signOut = true;
        if (Object.prototype.hasOwnProperty.call(resData, 'msg') && resData.msg !== '') {
          c.error({
            msg: resData.msg,
          }, c.vm, signOut);
        } else {
          c.error({
            msg: '請稍後再試！<程式錯誤訊息: response is empty.>',
          }, c.vm, signOut);
        }
      } else {
        c.error({
          msg: '請稍後再試！<程式錯誤訊息: response is empty.>',
        }, c.vm, signOut);
      }
    } // end _catch function

    if (!c.needAwait) {
      $http(_conf)
        .then((res) => {
          _then(res);
        })
        .catch((res) => {
          if (process.env.NODE_ENV === 'development') console.warn('catch res', res);
          _catch(res.response);
        }); // end $http
    } else {
      await $http(_conf)
        .then((res) => {
          _then(res);
        })
        .catch((res) => {
          if (process.env.NODE_ENV === 'development') console.log('await catch res', res, 'response', res.response);
          _catch(res.response);
        }); // end $http
    }

    return null;
  }, // end: ajaxFunc
  randomWord(len) {
    let str = '';
    const l = len && !Number.isNaN(len) ? len : 10;
    const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    // 隨機產生
    for (let i = 0; i < l; i += 1) {
      const pos = Math.round(Math.random() * (arr.length - 1));
      str += arr[pos];
    }

    return str;
  }, // end: randomWord

  lightenDarkenColor(col, amt) {
    /* eslint no-bitwise: ["error", { "allow": [">>", "&", "|", "<<"] }] */
    /* Usage
      // Lighten
      // let NewColor = LightenDarkenColor("#F06D06", 20);

      // Darken
      // let NewColor = LightenDarkenColor("#F06D06", -20);
    */

    let usePound = false;
    let _col = '';

    if (col[0] === '#') {
      _col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(_col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    // console.warn('lightenDarkenColor', r, g, b);

    return (usePound ? '#' : '') + (r !== 0 ? '' : '00') + (g | (b << 8) | (r << 16)).toString(16);
  }, // end lightenDarkenColor

  hexToRgbA(hex, opacity) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
    }
    throw new Error('Bad Hex');
  },

  paddingLeft(str, len) {
    return _paddingLeft(str, len);
  }, // end paddingLeft function

  search(conf) {
    const c = _extend({
      val: '',
      key: '',
      arr: [],
    }, conf);

    const target = c.val;
    const k = c.key;
    const a = c.arr;

    let index = -1;

    // console.warn('_search: target', target, 'key', key, 'arr', arr);

    a.every((obj, i) => {
      // console.warn('_search: i', i, ' typeof', (typeof obj === 'object'),
      //   'obj[key]', obj[key], 'obj[key] === target', (obj[key] === target));

      if (typeof obj === 'object' && obj[k] === target) {
        index = i;
        return false;
      }
      return true;
    });

    return index;
  }, // end search function
};
// end: base
