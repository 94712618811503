import Vue from 'vue';
import Vuex from 'vuex'; // 引入 vuex
import Cookie from 'js-cookie';
import base from './base';
import router from '../router';

Vue.use(Vuex); // 使用 vuex

export default new Vuex.Store({
  state: {
    // 初始化狀態
    taskTimer: null,
  }, // end state
  mutations: {
    // 處理狀態
    setTaskTimer(state, payload) {
      state.taskTimer = payload;
    },
    resetTaskTimer(state) {
      clearInterval(state.taskTimer);
    },
  }, // end mutations
  actions: {
    async userSignIn() {
      console.warn('userlogin redirect:', router.currentRoute.query.redirect);
      router.push({
        path: router.currentRoute.query.redirect || '/main',
      }).catch(() => {});
    }, // end userSignIn
    async userSignOut(context) {
      const _context = context;
      _context.commit('resetTaskTimer');
      await base.ajaxFunc({
        method: 'post',
        url: '/logout',
        data: {},
        success() {
          router.push({
            path: '/login',
          }).catch(() => {});
        },
        error() {
          console.warn('Logout request error!');
          Cookie.remove('ITRI_PT_Sessions');
          router.push({
            path: '/login',
          }).catch(() => {});
        },
      });
    }, // end userSignOut

    alert(context, payload) {
      const conf = {
        vm: Object.prototype.hasOwnProperty.call(payload, 'vm') ? payload.vm : null,
        type: Object.prototype.hasOwnProperty.call(payload, 'type') ? payload.type : 'danger',
        msg: Object.prototype.hasOwnProperty.call(payload, 'msg') ? payload.msg : '服務發生錯誤，請重新登入操作',
        callbackTimeout: Object.prototype.hasOwnProperty.call(payload, 'callbackTimeout') ? payload.callbackTimeout : 5500,
        callback() {
          if (Object.prototype.hasOwnProperty.call(payload, 'callback')) {
            payload.callback();
          }
        },
      };
      if (conf.vm) {
        conf.vm.$bvToast.toast(conf.msg, {
          title: conf.type === 'danger' ? '錯誤訊息' : '訊息',
          variant: conf.type === 'danger' ? 'danger' : 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          appendToast: true,
          noCloseButton: true,
        });
      } else {
        console.log(conf.type, conf.msg);
      }

      setTimeout(() => {
        conf.callback();
      }, conf.callbackTimeout);
    }, // end alert
  }, // end actions
  getters: {
  }, // end getters
});
