<template>
  <div class="pttool">
    <Header />
    <div class="content-block">
      <div class="actions-block">
        <b-button variant="outline-success" size="sm"
          @click.prevent="openModal({ title: '新增掃描' });"
         >
          <b-icon icon="plus"></b-icon>
          新增
        </b-button>
        <b-button variant="outline-primary" size="sm"
          :disabled="selectedRunning.length == 0"
          @click.prevent="pauseScan();"
         >
          <b-icon icon="pause-fill"></b-icon>
          暫停
        </b-button>
        <b-button variant="outline-primary" size="sm"
          :disabled="selectedPause.length == 0"
          @click.prevent="resumeScan();"
         >
          <b-icon icon="arrow-clockwise"></b-icon>
          繼續
        </b-button>
        <b-button variant="outline-warning" size="sm"
          :disabled="selectedRunning.length == 0 && selectedPause.length == 0"
          @click.prevent="cancelScan();"
         >
          <b-icon icon="stop-fill"></b-icon>
          停止
        </b-button>
        <b-button variant="outline-danger" size="sm"
          :disabled="selected.length == 0"
          @click.prevent="delScan();"
         >
          <b-icon icon="trash"></b-icon>
          刪除
        </b-button>
      </div>
      <!-- end: actions-block -->

      <!--
      <b-table class="task-table" hover show-empty
        ref="selectableTable" selectable @row-selected="selectedRow"
        :fields="taskFields" :items="taskList"
      >
      -->
      <b-table class="task-table" hover show-empty
        :fields="taskFields" :items="taskList" responsive
      >

        <template #head(selected)>
          <b-form-checkbox
            v-model="allChecked"
            @change="toggleAll"
            :indeterminate="indeterminate"
            size="lg"
          ></b-form-checkbox>
        </template>

        <template #cell(selected)="row">
          <b-form-checkbox
            :id="`task_${row.item.id}`"
            :key="`task_${row.item.id}`"
            :checked="selected.indexOf(row.item.id) >= 0"
            @change="selectedTask($event, row.item)"
            size="lg"
          ></b-form-checkbox>
        </template>
        <!-- end: selected cell -->

        <template #cell(status)="data">
          <div class="task-status"
            :class="{ 'success': data.value === 'done',
            'error': data.value === 'error',
            'warning': (data.value === 'stop' || data.value === 'cancel') }"
          >
            <b-icon icon="check-circle" v-if="data.value === 'done'"></b-icon>
            <b-icon icon="slash-circle" v-else-if="data.value === 'error'"></b-icon>
            <b-icon icon="pause-circle" v-else-if="data.value === 'pause'"></b-icon>
            <b-icon icon="stop-circle"
              v-else-if="data.value === 'stop' || data.value == 'cancel'">
            </b-icon>
            <b-icon icon="arrow-repeat" v-else></b-icon>

            <span>
              {{ data.value === 'start' || data.value == 'running'
                ? (data.item.hasOwnProperty('percentage') && data.item.percentage !== null
                  ? data.item.percentage + '%' : '0%')
                : (data.value === 'done' ? '完成'
                : (data.value === 'error' ? '失敗'
                : data.value === 'pause'? '暫停'
                : (data.value === 'stop' || data.value == 'cancel') ? '停止' : ''))
              }}
            </span>
          </div>
        </template>
        <!-- end: status -->

        <template #cell(rawdata)="data">
          <div class="task-report">
            <a class="download-link" download
              v-if="data.value && data.value != ''"
              :href="data.value"
            >
              Download
            </a>

            <p v-else>無</p>
          </div>
        </template>
        <!-- end: report -->

        <template #cell(startedAt)="data">
          <div class="task-scan-time">
            <p>
              {{ data.value ? $datetimeFormat(new Date(data.value), 'YYYY-MM-DD HH:mm:ss') : '' }}
            </p>
          </div>
        </template>
        <!-- end: scan time -->

        <template #empty>
          <div class="empty-block">
            <p>
              <b-icon icon="inbox-fill"></b-icon>
              No data found.
            </p>
          </div>
          <!-- end: empty block -->
        </template>

        <template #emptyfiltered>
          <div class="empty-block">
            <p>
              <b-icon icon="inbox-fill"></b-icon>
              No data found.
            </p>
          </div>
          <!-- end: empty block -->
        </template>
      </b-table>
    </div>
    <!-- end: content block -->

    <!-- Modal -->
    <b-modal id="newTaskModal" hide-footer scrollable
      :title="modalTitle">

      <template #modal-header-close>
        <b-button variant="link" @click="closeModal()">
          <b-icon icon="x"></b-icon>
        </b-button>
      </template>

      <b-tabs pills v-model="newScanTabIndex">
        <b-tab title="Manual" active>
          <div class="modal-scan-manual">
            <b-form @submit="submitScan" @reset="formReset">

              <b-form-group
                label="* 表示為必填欄位"
                class="text-left"
              ></b-form-group>

              <b-form-group
                id="input-group-target"
                label="Target*"
                label-for="input-target"
                label-cols="2"
              >
                <b-form-input
                  id="input-target"
                  v-model="scanForm.target"
                  type="text"
                  placeholder="Please enter IP address format(192.168.1.1) or URL format(https://www.google.com.tw)."
                  required
                  :state="targetState"
                ></b-form-input>
                <b-form-invalid-feedback id="input-target">
                  {{Object.keys(err).length > 0 && err.hasOwnProperty('target')? err.target: ''}}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-desc"
                label="Description"
                label-for="input-desc"
                label-cols="2"
              >
                <b-form-input
                  id="input-desc"
                  v-model="scanForm.desc"
                  type="text"
                  placeholder="Please enter the description of target."
                ></b-form-input>
                <b-form-invalid-feedback id="input-desc">
                  {{Object.keys(err).length > 0 && err.hasOwnProperty('desc')? err.desc: ''}}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-desc"
                label="Recording"
                label-cols="2"
              >
                <b-button pill type="button" variant="warning"
                  v-if="!isAutoLoginExtensionExist"
                  :disabled="isAutoLoginStart"
                  @click="recordAutoLogin"
                >
                <template v-if="!isAutoLoginStart">
                   <b-icon icon="play"></b-icon>
                   Start
                 </template>
                 <template v-else>
                   <b-icon icon="arrow-repeat"></b-icon>
                   Preparing
                 </template>
                </b-button>

                <b-button pill type="button" variant="danger"
                  v-else
                  @click="stopAutoLogin"
                >
                  <b-icon icon="stop-fill"></b-icon>
                  Stop
                </b-button>
                <b-form-text>
                  使用前請先下載並詳閱
                  <a class="download-link" href="/download/Scorpione_manual.zip" download>使用說明手冊</a>
                </b-form-text>
              </b-form-group>

              <div class="button-block">
                <b-button pill type="button" variant="outline-secondary"
                  class="mr-2"
                  @click="closeModal"
                >
                  取消
                </b-button>
                <b-button pill type="button" variant="outline-primary" @click="submitScan">
                  新增
                </b-button>
              </div>
            </b-form>
          </div>
        </b-tab>
        <!-- end: Manual -->

        <b-tab title="From VPN tunnel">
          <div class="modal-scan-vpn">
            <b-form @submit="submitTCPScan" @reset="formReset">

              <div class="choose-device-block">
                <div class="step-block">
                  <div class="step-num">
                    <p>Step 01</p>
                  </div>

                  <div class="step-icon">
                    <b-icon icon="display"></b-icon>
                  </div>

                  <div class="step-title">
                    <p>Choose Device</p>
                  </div>
                </div>
                <!-- end: step -->

                <div class="list-radio"
                  v-if="natDeviceList.length > 0"
                >
                  <b-form-group>
                    <b-form-radio name="nat-device"
                      v-for="(deviceObj) in natDeviceList"
                      :key="deviceObj.id"
                      :value="deviceObj.id"
                      v-model="selectedNatDeviceId"
                    >
                      {{  Object.prototype.hasOwnProperty.call(deviceObj, 'name') ?
                          deviceObj.name : 'Name Err' }}
                    </b-form-radio>
                  </b-form-group>
                </div>

                <div class="empty-block" v-else>
                  <p>
                    <b-icon icon="inbox-fill"></b-icon>
                    Nothing
                  </p>
                  <small>Please choose "Manual" to add a task.</small>
                </div>
                <!-- end: empty block -->
              </div>
              <!-- end: choose device block -->

              <div class="choose-target-block">
                <div class="step-block">
                  <div class="step-num">
                    <p>Step 02</p>
                  </div>

                  <div class="step-icon">
                    <b-icon icon="diagram3"></b-icon>
                  </div>

                  <div class="step-title">
                    <p>Choose Scan Target</p>
                  </div>
                </div>
                <!-- end: step -->

                <div class="list-radio"
                  v-if="natDeviceList.length > 0
                  && natDeviceList.filter(item => {
                    return item.id == selectedNatDeviceId;
                  }).length > 0"
                >
                  <b-form-group v-if="natDeviceList.filter(item => {
                      return item.id == selectedNatDeviceId;
                    })[0].ipList"
                  >
                    <b-form-radio name="subnet-ip"
                      v-for="(ip, j) in natDeviceList.filter(item => {
                        return item.id == selectedNatDeviceId;
                      })[0].ipList"
                      :key="`subnet-ip-${j}`"
                      :value="ip"
                      v-model="scanForm.target"
                    >
                      {{  ip }}
                    </b-form-radio>
                  </b-form-group>

                  <div class="empty-block" v-else>
                    <p>
                      <b-icon icon="arrow-left-circle"></b-icon>
                      Please select another device in the left.
                    </p>
                  </div>
                  <!-- end: empty block -->
                </div>

                <div class="empty-block" v-else>
                  <p>
                    <b-icon icon="arrow-left-circle"></b-icon>
                    Please select a device in the left.
                  </p>
                </div>
                <!-- end: empty block -->
              </div>
              <!-- end: choose target block -->
            </b-form>

            <small class="err-txt"
              v-if="Object.keys(err).length > 0
              && Object.prototype.hasOwnProperty.call(err, 'target')"
            >
              {{ err.target }}
            </small>

            <div class="button-block">
              <b-button pill type="button" variant="outline-secondary"
                class="mr-2"
                @click="closeModal"
              >
                取消
              </b-button>
              <b-button pill type="button" variant="outline-primary" @click="submitTCPScan">
                新增
              </b-button>
            </div>
          </div>
        </b-tab>
        <!-- end: VPN -->

        <b-tab title="From CSV File">
          <div class="modal-scan-csv">
            <b-form @submit="submitCSVScan" @reset="formReset">

              <b-form-group
                id="input-group-desc"
                label="CSV File"
                label-cols="2"
              >
                <b-form-file
                  id="upload-csv"
                  v-model="scanCSVFile"
                  placeholder="請選擇檔案"
                  accept="text/csv"
                  :no-drop="true"
                  :state="csvState"
                ></b-form-file>
              </b-form-group>
            </b-form>

            <small class="err-txt"
              v-if="Object.keys(err).length > 0
              && Object.prototype.hasOwnProperty.call(err, 'target')"
            >
              {{ err.target }}
            </small>

            <div class="button-block">
              <b-button pill type="button" variant="outline-secondary"
                class="mr-2"
                @click="closeModal"
              >
                取消
              </b-button>
              <b-button pill type="button" variant="outline-primary" @click="submitCSVScan">
                新增
              </b-button>
            </div>
          </div>
        </b-tab>
        <!-- end: VPN -->
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import Header from '@/layout/Header.vue';

export default {
  name: 'PTTool',
  components: {
    Header,
  },
  data() {
    return {
      taskFields: [
        {
          key: 'selected',
          label: '',
        }, {
          key: 'target',
          label: 'Target',
        }, {
          key: 'description',
          label: 'Description',
        }, {
          key: 'status',
          label: 'Status',
        }, {
          key: 'rawdata',
          label: 'Report',
        }, {
          key: 'startedAt',
          label: 'Scan Time',
        },
      ],
      taskList: [],
      autoRefreshSecond: (process.env.NODE_ENV === 'development') ? 5 : 15,

      selected: [],
      selectedRunning: [],
      selectedPause: [],

      modalTitle: '',

      err: {},
      scanForm: {
        id: 0,
        status: 'start',
        target: '',
        desc: '',
        RecordEvents: '',
      },
      isAutoLoginExtensionExist: false,
      isAutoLoginStart: false,
      autoLoginResponseSecond: 0.5,

      newScanTabIndex: 0,
      natDeviceList: [],
      selectedNatDeviceId: 0,
      scanFormFilled: false, // for validation
      scanFormTCPFilled: false, // for validation

      allChecked: false,
      indeterminate: false,

      scanCSVFile: null, // for scan csv file
    };
  },
  mounted() {
    window.addEventListener('PTP_EXT_MSG_FROM', this.autoLoginExtensionHandler);
    // end add event: auto login extension response

    this.getTaskList();
  }, // end mounted
  beforeDestroy() {
    window.removeEventListener('PTP_EXT_MSG_FROM', this.autoLoginExtensionHandler);
  },
  computed: {
    targetState() {
      if (!this.scanFormFilled) return null;
      this.verifyScan();
      return !(Object.keys(this.err).length > 0 && Object.prototype.hasOwnProperty.call(this.err, 'target'));
    },
    csvState() {
      if (!this.scanCSVFile) return null;
      this.verifyCSVScan();
      return !(Object.keys(this.err).length > 0 && Object.prototype.hasOwnProperty.call(this.err, 'target'));
    },
  },
  watch: {
    newScanTabIndex() {
      this.changeNewScanTab(this.newScanTabIndex);
    },
    selectedNatDeviceId() {
      const _this = this;
      if (_this.selectedNatDeviceId === 0
          // eslint-disable-next-line
          || !_this.natDeviceList.filter((item) => item.id === _this.selectedNatDeviceId)[0].ipList) {
        _this.scanForm.target = '';
      }
    }, // end selectedNatDeviceId
    selected: {
      handler(newSelected) {
        if (newSelected.length === 0) {
          this.indeterminate = false;
          this.allChecked = false;
        } else if (newSelected.length === this.taskList.length) {
          this.indeterminate = false;
          this.allChecked = true;
        } else {
          this.indeterminate = true;
          this.allChecked = false;
        }
      },
      deep: true,
    },
  }, // end watch
  methods: {
    reloadData() {
      const _this = this;

      _this.getTaskList();
    }, // end reloadData
    resetActions(conf) {
      const _this = this;

      const c = _this.$base.extend({
        selectedId: [],
      }, conf);

      const selectedIds = c.selectedId;
      _this.selectedRunning = [];
      _this.selectedPause = [];

      _this.taskList.forEach((task) => {
        // console.warn('selected', selectedId, selectedId.includes(task.id));

        if (selectedIds.length > 0 && selectedIds.includes(task.id)) {
          if (task.status === 'running' || task.status === 'start') {
            _this.selectedRunning.push(task.id);
          }

          if (task.status === 'pause') {
            _this.selectedPause.push(task.id);
          }
        }
      });

      // console.warn('selected', _this.selected);
      // console.warn('selectedRunning', _this.selectedRunning);
      // console.warn('selectedPause', _this.selectedPause);
    }, // end: resetActions
    formReset() {
      const _this = this;

      _this.selectedNatDeviceId = 0;
      _this.natDeviceList = [];

      _this.err = {};
      _this.scanForm = {
        id: 0,
        status: 'start',
        target: '',
        desc: '',
        RecordEvents: '',
      };
      _this.scanFormFilled = false;
      _this.scanFormTCPFilled = false;
      _this.scanCSVFile = null;
    }, // end: formReset
    setTaskStatusTimer() {
      const _this = this;
      _this.stopTaskStatusTimer();

      const t = setInterval(() => {
        _this.reloadData();
      }, _this.autoRefreshSecond * 1000);
      _this.$store.commit('setTaskTimer', t);
    }, // end: setTaskStatusTimer
    stopTaskStatusTimer() {
      this.$store.commit('resetTaskTimer');
    }, // end: stopTaskStatusTimer

    openModal(conf) {
      const _this = this;
      const c = _this.$base.extend({
        title: '',
      }, conf);

      const t = c.title;

      _this.$bvModal.show('newTaskModal');
      _this.modalTitle = t;
    }, // end openModal
    closeModal() {
      const _this = this;

      _this.formReset();
      _this.$bvModal.hide('newTaskModal');
    },
    // end: closeModal

    changeNewScanTab(tabIndex) {
      const _this = this;

      // console.warn('tab', tab);

      _this.formReset();

      if (tabIndex === 1) {
        _this.getNatDevices();
      }
    }, // end: changeNewScanTab

    selectedTask(ev, item) {
      const _this = this;
      if (process.env.NODE_ENV === 'development') console.warn('selectedTask', ev, item.id);
      if (ev) {
        _this.selected.push(item.id);

        if (item.status === 'running' || item.status === 'start') {
          _this.selectedRunning.push(item.id);
        }

        if (item.status === 'pause') {
          _this.selectedPause.push(item.id);
        }
      } else {
        const i = _this.selected.indexOf(item.id);
        if (i > -1) {
          _this.selected.splice(i, 1);
        }
        const j = _this.selectedRunning.indexOf(item.id);
        if (j > -1) {
          _this.selectedRunning.splice(j, 1);
        }
        const k = _this.selectedPause.indexOf(item.id);
        if (k > -1) {
          _this.selectedPause.splice(k, 1);
        }
      }
      // console.warn('selectedTask selected', this.selected);
    }, // end selectedTask
    toggleAll(checked) {
      this.selected = [];
      this.selectedRunning = [];
      this.selectedPause = [];
      this.taskList.forEach((item) => {
        this.selectedTask(checked, item);
      });
    }, // end toggleAll
    // selectedRow(items) {
    //   console.warn('selectedRow', items);
    // }, // end selectedRow
    // selectAllTasks() {
    //   const _this = this;
    //   _this.$refs.selectableTable.selectAllRows();
    // }, // end selectAllTasks

    async getTaskList() {
      const _this = this;

      // stop timer
      _this.stopTaskStatusTimer();

      let tmpTasks = [];
      let isAllComplete = true;

      await _this.$base.ajaxFunc({
        needAwait: true,
        method: 'get',
        url: '/task',
        params: {},
        success(res) {
          if (Object.prototype.hasOwnProperty.call(res, 'rows') && res.rows.length >= 0) {
            tmpTasks = res.rows;
          } // end: has task
        }, // end success
        vm: _this,
      }); // end call: ajaxFunc

      if (process.env.NODE_ENV === 'development') console.warn('tmpTasks', tmpTasks);

      tmpTasks.every((job) => {
        const status = Object.prototype.hasOwnProperty.call(job, 'status')
          && job.status !== null ? job.status : '';

        // let nodeId = Object.prototype.hasOwnProperty.call(job, 'machine_id')
        //   && job.machine_id !== null ? job.machine_id : '';
        // console.warn('node_id', node_id, 'status', status);

        if (status === 'start' || status === 'running') {
          isAllComplete = false;
          return false;
        }
        return true;
      }); // end for: job

      if (process.env.NODE_ENV === 'development') console.warn('isAllComplete', isAllComplete);

      if (!isAllComplete) {
        _this.setTaskStatusTimer();
      } // end if: task not complete

      _this.taskList = tmpTasks;

      // check if still selected or not
      const prevSelected = _this.selected;
      prevSelected.forEach((selectedId) => {
        if (_this.$base.search({
          val: selectedId,
          key: 'id',
          arr: tmpTasks,
        }) === -1) {
          _this.selected.splice(prevSelected.indexOf(selectedId));
        }
      }); // end for: selected

      _this.resetActions({
        selectedId: _this.selected,
      });
    }, // end: getTaskList
    async getNatDevices() {
      const _this = this;

      await _this.$base.ajaxFunc({
        needAwait: true,
        method: 'get',
        url: '/natDevices',
        params: {},
        success(res) {
          if (process.env.NODE_ENV === 'development') console.warn('getNatDevices res', res);

          if (res.length > 0) {
            _this.natDeviceList = res;
          } else {
            _this.natDeviceList = [];
          }
        }, // end success
        vm: _this,
      }); // end call: ajaxFunc
    }, // end: getNatDevices

    ajaxScan(conf) {
      const _this = this;

      const c = _this.$base.extend({
        action: 'new',
        msg: '',
        command: _this.scanForm.status,
        target: _this.scanForm.target,
        description: _this.scanForm.desc,
        RecordEvents: _this.scanForm.RecordEvents,
      }, conf);

      // setup data and url according action
      let data;
      let url;
      switch (c.action) {
        /* eslint dot-notation: "warn" */
        case 'new':
          data = {
            command: c.command,
            description: c.description,
            target: c.target,
          };
          if (c.RecordEvents) {
            data.RecordEvents = c.RecordEvents;
          }
          url = '/task';
          break;
        case 'newTCP':
          data = { id: c.id, target: c.target };
          url = '/natDevice/task';
          break;
        case 'newCSV':
          data = new FormData();
          data.append('taskList', _this.scanCSVFile);
          url = '/task/addcsv';
          break;
        default:
          data = {
            command: c.command,
            description: c.description,
            target: c.target,
          };
          data.id = c.id;
          url = '/task';
      }

      _this.$base.ajaxFunc({
        method: 'post',
        url,
        data,
        success(res) {
          _this.reloadData();
          _this.closeModal();

          if (Object.prototype.hasOwnProperty.call(res, 'msg') && res.msg !== '') {
            _this.$store.dispatch('alert', {
              vm: _this,
              type: 'success',
              msg: res.msg,
            });
          }
        }, // end success
        vm: _this,
      }); // end call: ajaxFunc
    }, // end: ajaxScan
    pauseScan() {
      const _this = this;

      _this.selected.forEach((selectedId) => {
        _this.taskList.every((task) => {
          if ((task.status === 'running' || task.status === 'start')
            && task.id === selectedId) {
            if (process.env.NODE_ENV === 'development') console.warn('pause: selected task', task);
            _this.ajaxScan({
              action: 'pause',
              id: task.id,
              command: 'pause',
              target: task.target,
            });
            return false;
          }
          if (task.id === selectedId) return false;
          return true;
        }); // end for: task
      }); // end for: selected task
    }, // end: pauseScan
    resumeScan() {
      const _this = this;

      _this.selected.forEach((selectedId) => {
        _this.taskList.every((task) => {
          if (task.status === 'pause' && task.id === selectedId) {
            if (process.env.NODE_ENV === 'development') console.warn('resume: selected task', task);
            _this.ajaxScan({
              action: 'resume',
              id: task.id,
              command: 'resume',
              target: task.target,
            });
            return false;
          }
          if (task.id === selectedId) {
            return false;
          }
          return true;
        }); // end for: task
      }); // end for: selected task
    }, // end: resumeScan
    cancelScan() {
      const _this = this;

      _this.selected.forEach((selectedId) => {
        _this.taskList.every((task) => {
          if ((task.status === 'running' || task.status === 'start' || task.status === 'pause')
            && task.id === selectedId) {
            if (process.env.NODE_ENV === 'development') console.warn('cancel: selected task', task);
            _this.ajaxScan({
              action: 'cancel',
              id: task.id,
              command: 'cancel',
              target: task.target,
            });
            return false;
          }
          if (task.id === selectedId) {
            return false;
          }
          return true;
        }); // end for: task
      }); // end for: selected task
    }, // end: cancelScan
    delScan() {
      const _this = this;

      _this.selected.forEach((selectedId) => {
        _this.taskList.every((task) => {
          if (task.id === selectedId) {
            if (process.env.NODE_ENV === 'development') console.warn('delete: selected task', task);
            _this.ajaxScan({
              action: 'delete',
              id: task.id,
              command: 'delete',
              target: task.target,
            });
            return false;
          }
          return true;
        }); // end for: task
      }); // end for: selected task
    }, // end: delScan

    verifyScan() {
      const _this = this;

      // verify: Target
      if (_this.$verify.isEmpty({
        val: _this.scanForm.target,
      })) {
        _this.$set(_this.err, 'target', _this.newScanTabIndex === 0 ? '請輸入 Target' : '請選擇 Target');
      } else if (!_this.$verify.isIP({
        val: _this.scanForm.target,
      }) && !_this.$verify.isURL({
        val: _this.scanForm.target,
      })) {
        _this.$set(_this.err, 'target', '格式錯誤：請輸入 IP address (e.g. 192.168.1.1) 或 URL (e.g. https://www.google.com.tw)');
      } else {
        _this.$delete(_this.err, 'target');
      }
    }, // end: verifyScan
    submitScan() {
      const _this = this;
      _this.verifyScan();

      if (Object.keys(_this.err).length === 0) {
        _this.scanForm.id = 0;
        _this.scanForm.status = 'start';

        _this.ajaxScan({
          action: 'new',
          msg: '已新增！',
        });
      } // end if: no error
      _this.scanFormFilled = true;
    }, // end: submitScan

    autoLoginExtensionHandler(event) {
      const _this = this;
      if (process.env.NODE_ENV === 'development') console.warn(`autoLoginExtensionHandler event ${event}`);

      if (event.detail.hash === '4f134a5609dcabeccf862de3c80f44ca') {
        if (event.detail.id === 'DETECTION_RESPONSE') {
          // Handle when the extension confirmed its present in the web browser
          _this.setAutoLoginExtension();
        }
        if (event.detail.id === 'SEND_FILE') {
          if (process.env.NODE_ENV === 'development') {
            console.log(`Filename: ${event.detail.filename}`,
              `File Content: ${event.detail.content}`);
          }
          // Handle the reception of the python file
          // File under:
          //  event.detail.filename
          //  event.detail.content

          if (event.detail.content && event.detail.content !== '') {
            _this.setAutoLoginFile({
              data: event.detail.content,
            });
          } else {
            _this.stopAutoLoginErr();
          }
        }
      }
    }, // end autoLoginExtensionHandler
    detectExtensionExist() {
      const _this = this;
      const evAutoLoginDetection = new CustomEvent('PTP_EXT_MSG_TO', {
        detail: {
          id: 'DETECTION_REQUEST',
          hash: '60aa5456d1533f99d4422335b448a8bb',
        },
      });
      window.dispatchEvent(evAutoLoginDetection);

      setTimeout(() => {
        if (!_this.isAutoLoginExtensionExist) {
          _this.isAutoLoginStart = false;
          _this.scanForm.RecordEvents = '';

          _this.$store.dispatch('alert', {
            vm: _this,
            type: 'danger',
            msg: 'Please install the Auto-login extension for recording!',
          });
        } else {
          _this.startRecording();
        }
      }, _this.autoLoginResponseSecond * 1000);
    }, // end: detectExtensionExist
    setAutoLoginExtension() {
      const _this = this;
      _this.isAutoLoginExtensionExist = true;
    }, // end: AutoLoginExtension
    startRecording() {
      const _this = this;
      const evAutoLoginStart = new CustomEvent('PTP_EXT_MSG_TO', {
        detail: {
          id: 'START_RECORDING',
          hash: '60aa5456d1533f99d4422335b448a8bb',
          url: _this.scanForm.target,
        },
      });
      window.dispatchEvent(evAutoLoginStart);
    }, // end: startRecording
    recordAutoLogin() {
      const _this = this;

      // check target isn't empty
      _this.verifyScan();
      if (Object.keys(_this.err).length === 0) {
        _this.detectExtensionExist();
        _this.isAutoLoginStart = true;
      }
    }, // end: recordAutoLogin
    stopAutoLogin() {
      const _this = this;
      const evAutoLoginStop = new CustomEvent('PTP_EXT_MSG_TO', {
        detail: {
          id: 'STOP_RECORDING',
          hash: '60aa5456d1533f99d4422335b448a8bb',
        },
      });
      window.dispatchEvent(evAutoLoginStop);

      setTimeout(() => {
        if (_this.isAutoLoginExtensionExist) {
          _this.stopAutoLoginErr();
        }
      }, _this.autoLoginResponseSecond * 1000);
    }, // end: stopAutoLogin
    stopAutoLoginErr() {
      const _this = this;

      _this.isAutoLoginExtensionExist = false;
      _this.isAutoLoginStart = false;
      _this.scanForm.RecordEvents = '';

      _this.$store.dispatch('alert', {
        vm: _this,
        type: 'danger',
        msg: 'Recording is failed, please record again.',
      });
    }, // end: stopAutoLoginErr
    setAutoLoginFile(conf) {
      const _this = this;
      const c = _this.$base.extend({
        data: '',
      }, conf);

      _this.scanForm.RecordEvents = c.data;
      _this.isAutoLoginExtensionExist = false;
      _this.isAutoLoginStart = false;
    }, // end: setAutoLoginFile

    verifyTCPScan() {
      const _this = this;

      // verify: Target
      if (_this.$verify.isEmpty({
        val: _this.scanForm.target,
      })) {
        _this.$set(_this.err, 'target', '請選擇 Target');
      } else {
        _this.$delete(_this.err, 'target');
      }
    }, // end: verifyTCPScan
    submitTCPScan() {
      const _this = this;
      _this.verifyTCPScan();

      if (Object.keys(_this.err).length === 0) {
        _this.ajaxScan({
          action: 'newTCP',
          id: _this.selectedNatDeviceId,
          msg: '已新增！',
        });
      } // end if: no error
    }, // end: submitTCPScan

    verifyCSVScan() {
      const _this = this;

      // verify: Target
      if (!_this.$verify.isCSV({
        val: _this.scanCSVFile,
      })) {
        _this.$set(_this.err, 'target', '請選擇 CSV');
      } else if (_this.$verify.isSizeLimit({
        val: _this.scanCSVFile,
      })) {
        _this.$set(_this.err, 'target', '檔案大小不得大於 1MB');
      } else {
        _this.$delete(_this.err, 'target');
      }
    }, // end: verifyCSVScan

    submitCSVScan() {
      const _this = this;
      _this.verifyCSVScan();

      if (Object.keys(_this.err).length === 0) {
        _this.ajaxScan({
          action: 'newCSV',
          msg: '已新增！',
        });
      } // end if: no error
    }, // end: submitCSVScan
  }, // end methods
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content-block > * {
  max-width: 80%;
  margin: 0 auto;

  @media all and (max-width: 1024px) {
      max-width: 920px;
  }
}
.actions-block {
  margin-bottom: $margin-md;

  .btn {
    margin-right: 10px;

    &.disabled {
      border-color: $note-color !important;
      color: $note-color !important;
      cursor: not-allowed;
    }
  }
} // end action block
.task-table {
  ::v-deep {
    text-align: center;
    thead th {
      border-top: 0;
      border-bottom-color: $main-color;
      color: $main-color;
    } // end th

    td {
      min-width: 80px;
      color: $main-color;
      max-width: 500px;
      word-wrap: break-word;
      p {
        margin-bottom: 0;

        & + p {
          margin-top: $margin-sm;
        }
      }
    } // end td
  } // end table

  .empty-block {
    margin-top: $margin-md;
  }

  .task-status {
    color: $info-color;

    &.success {
      color: $success-color;
    }
    &.error {
      color: $error-color;
    }
    &.warning {
      color: $warning-color;
    }
  } // end task status

  .task-report {
    .download-link {
      text-decoration: underline;
    }
  }
} // end task table

::v-deep .modal {
  &.show .modal-dialog {
    margin: 0;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    background-color: rgba(255, 255, 255, .9);
    height: 100%;
    max-height: inherit;
  }

  .close .btn {
    padding: 0;
    font-size: 1.5rem;
    color: $dark-color-grey;
    line-height: 1rem;
  }

  .tabs .nav {
    justify-content: center;
  }

  .modal-scan-manual {
    margin: 20px 0;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e5e5e5;

    .col-form-label {
      text-align: right;
    } // end label
    .text-left .col-form-label {
      text-align: left;
    }

    .download-link {
      text-decoration: underline;
    }
  } // end modal scan manual

  .modal-scan-vpn {
    margin: 20px 0;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e5e5e5;

    form {
      display: flex;

      &> div {
        width: 50%;
        padding: 0 $margin-sm;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    } // end form

    .choose-target-block {
      border-left: 1px solid #e2e2e2;
    }

    .step-block {
      margin-bottom: $margin-lg;
      text-align: center;

      > *:not(:first-child) {
        margin-top: $margin-xs;
      }

      .step-num {
        font-weight: bold;
        color: #9e9e9e;
        p {
          margin: 0;
        }
      } // end step num

      .step-icon {
        margin: 0;
        color: $dark-color-grey;
        font-size: 3rem;
      } // end step icon

      .step-title {
        margin: 0;
        font-weight: bold;
        font-size: 18px;
        p {
          margin: 0;
        }
      } // end step title
    } // end: step

    .custom-radio {
      margin-bottom: 10px;

      :disabled ~ .custom-control-label,
      [disabled] ~ .custom-control-label,
      .disabled ~ .custom-control-label {
        cursor: not-allowed;
      }
    }
    .custom-control-label {
      cursor: pointer;
    }

    .empty-block {
      color: #607d8b;
    }

    .err-txt {
      display: block;
      margin-top: 20px;
      margin-bottom: -20px;
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      color: $error-color;
    }
  } // end modal scan vpn

  .modal-scan-csv {
    margin: 20px 0;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e5e5e5;

    .col-form-label {
      text-align: right;
    } // end label
    .text-left .col-form-label {
      text-align: left;
    }

    .err-txt {
      display: block;
      margin-top: 20px;
      margin-bottom: -20px;
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      color: $error-color;
    }
  } // end modal scan csv

  .button-block {
    margin-top: 30px;
    text-align: center;
  }
} // end modal
</style>
