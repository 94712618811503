<template>
  <div class="register">
    <Header />
    <div class="content-block">
      <div class="service-block">
        <b-form @submit="submitRegister">
          <ol>
            <li>
              <p><b>使用注意事項：</b></p>
              <ul>
                <li>
                  <p>本院僅同意授權您於非商業目的之前提下，將本服務用於進行您個人或所屬法人擁有的作業環境範圍內之單機檢測。
                    如有違反，本院有權停止您使用本服務，倘因此造成本院受有損害，並應負賠償責任。本院保留未在本服務使用條款中明確授與您的所有權限。</p>
                </li>
                <li>
                  <p>本服務可能會提供您至其他網站、網路資源或程式的連結，本院對於被連結之其他網站、網路資源或程式，
                    均不擔保其有效性、正確性、合法性及完整性，您應於連結或存取前自行斟酌與判斷。</p>
                </li>
                <li>
                  <p>您承諾不以非法目的或非法方式使用本服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。
                    您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。</p>
                </li>
                <li>
                  <p>本服務「僅供申請者將用於其所屬企業組織之工作流程的範圍內，以輔助實施適當的內部資安程序和監督作業」；
                    本服務並未設計提供或在未經許可狀況下，於危險環境或其他故障可能導致財產損失或人身傷害的應用本服務，
                    特別聲明本服務將不會為申請者承擔任何法律責任，申請者應自行承擔與使用服務相關的所有風險。</p>
                </li>
                <li>
                  <p>本服務「僅供申請者將用於其所屬企業組織之工作流程的範圍內，以輔助實施適當的內部資安程序和監督作業」；本服務並未設計提供或在未經許可狀況下，
                  於危險環境或其他故障可能導致財產損失或人身傷害的應用本服務，特別聲明本服務將不會為申請者承擔任何法律責任，申請者應自行承擔與使用服務相關的所有風險。</p>
                </li>
                <li>
                  <p>
                    本院將以合理之方式及技術，維護服務之正常運作。
                    但本服務可能會出現中斷或故障等現象，可能造成您使用上的不便、資料遺失、錯誤或其他時間及經濟上之損害等情形，
                    建議您使用本服務時應自行採取適當的防護措施。
                  </p>
                </li>
                <li>
                  <p>
                    若您違反上述任何內容，
                    <b>本網站有權暫停、終止或拒絕您使用本服務之全部或部分。</b>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p><b>認知及接受條款</b></p>
              <ul>
                <li><p>當您使用財團法人工業技術研究院（下稱「本院」）提供之服務（下稱「本服務」）時，即視為您已經閱讀、瞭解並同意接受本服務使用條款。</p></li>
                <li>
                  <p>
                    本院有權不經通知修改本服務使用條款，並在公告於本服務之網站之時即生效。
                    如您於本服務使用條款修改後繼續使用本服務，視為您已經閱讀、瞭解並同意接受相關修改。
                    如不同意，您應立即停止使用本服務。本院保留對於本服務使用條款的最終解釋權。
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <b>免責聲明</b>
              </p>
              <ul>
                <li>
                  <p>本院對於本服務不負明示或默示之擔保責任，包括但不限於不保證本服務之穩定、無誤、不中斷、符合您的需求及未侵害他人權利，
                    對於您因使用或無法使用本服務所造成的直接或間接損害，亦不負賠償責任。</p>
                </li>
                <li>
                  <p>本服務係按現狀提供，本院不保證本服務所提供的軟體、
                    程式或資料不會含有電腦病毒等有害物，您應自負風險。</p>
                </li>
                <li>
                  <p>您使用本服務所取得之建議和資訊，無論形式為何，
                    本院均不對其內容負保證責任。</p>
                </li>
              </ul>
            </li>
            <li>
              <p><b>註冊網域：</b></p>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <b-form-input
                    id="input-domain"
                    v-model="domain"
                    type="url"
                    placeholder="請輸入您的 domain"
                    required
                    :state="domainState"
                    @key.enter="submitRegister"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-domain">
                    {{Object.keys(err).length > 0 && err.hasOwnProperty('domain')? err.domain: ''}}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </li>

            <li class="list-type-none">
              <b-form-checkbox
                v-model="serviceCheck"
              >
                我已閱讀並了解服務限制與聲明，且同意接受及遵守服務使用條款所述之內容。
              </b-form-checkbox>
            </li>

            <li class="list-type-none text-center">
              <b-button variant="outline-primary"
                :disabled="!serviceCheck"
                @click.prevent="submitRegister"
               >
                註冊
              </b-button>
            </li>
          </ol>
        </b-form>
      </div>
      <!-- end: service block -->
    </div>
    <!-- end: content block -->
  </div>
</template>

<script>
import Header from '@/layout/Header.vue';

export default {
  name: 'RegisterDomain',
  components: {
    Header,
  },
  data() {
    return {
      domain: '',
      serviceCheck: false,
      err: {},
      domainFilled: false,
    };
  },
  computed: {
    domainState() {
      if (!this.domainFilled) return null;
      this.verifyRegister();
      return !(Object.keys(this.err).length > 0 && Object.prototype.hasOwnProperty.call(this.err, 'domain'));
    },
  },
  methods: {
    resetForm() {
      const _this = this;

      _this.domain = '';
      _this.err = {};
      _this.domainFilled = false;
      _this.serviceCheck = false;
    }, // end resetForm
    verifyRegister() {
      const _this = this;

      // verify: domain
      if (_this.$verify.isEmpty({
        val: _this.domain,
      })) {
        _this.$set(_this.err, 'domain', '請輸入 Domain');
      } else if (!_this.$verify.isDomain({
        val: _this.domain,
      })) {
        _this.$set(_this.err, 'domain', '格式錯誤：請輸入合法domain, e.g. google.com');
      } else {
        _this.$delete(_this.err, 'domain');
      }
    }, // end verifyRegister
    submitRegister() {
      const _this = this;
      _this.verifyRegister();

      if (Object.keys(_this.err).length === 0) {
        _this.$base.ajaxFunc({
          method: 'post',
          url: '/ssc/register',
          data: {
            domain: _this.domain,
          },
          success() {
            _this.resetForm();

            _this.$store.dispatch('alert', {
              vm: _this,
              type: 'success',
              msg: '已成功註冊！ 請等候頁面跳轉...',
              callback() {
                _this.$router.push('/metering');
              },
              callbackTimeout: 2000,
            });
          }, // end success
          vm: _this,
        }); // end call: ajaxFunc
      } // end if: no error
      this.domainFilled = true;
    }, // end submitRegister
  }, // end methods
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.service-block {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: $main-color-white;
  border-radius: 10px;

  @media all and (max-width: 1024px) {
      max-width: 920px;
  }

  li {
    margin-bottom: 1rem;

    &.list-type-none {
      list-style-type: none;
    }

    p {
      margin-bottom: 0;

      & + * {
        margin-top: 1rem;
      }
    } // end p
  } // end li

  ::v-deep .custom-control-label {
    cursor: pointer;
  }

  ::v-deep .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    color: $note-color;
    border-color: $note-color;
  }
} // end service block
</style>
