<template>
  <div class="unsubscribe">
    <div class="content-block">
      <div class="service-block">
        <b-form @submit.prevent="submitUnSubscribe">
          <div style="text-align:center"><h2>取消訂閱</h2></div>
            <ul>
              <li class="list-type-none"><h3>你確定要取要訂閱嗎？</h3></li>
              <li class="list-type-none"><p>當你取消訂閱後，
                你將不會再收到常見漏洞與披露（Common Vulnerabilities and Exposures，CVE）
                相關分析報告與驗證用測試程式的資安情資。</p></li>
              <li class="list-type-none"><p>你必須重新訂閱才能再次收到我們的資安情資電子報。</p></li>

              <li class="list-type-none">
                <p>若確定取消，請在下方輸入欲退訂的E-mail：</p>
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <b-form-input
                      id="input-email"
                      v-model="email"
                      type="email"
                      placeholder="請輸入您的 E-mail"
                      required
                      :state="emailState"
                      @key.enter="submitUnSubscribe"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-email">
                      {{Object.keys(err).length > 0 && err.hasOwnProperty('email')? err.email: ''}}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </li>

              <li class="list-type-none text-center">
                <b-button variant="outline-primary"
                  @click.prevent="submitUnSubscribe"
                 >
                  確認取消
                </b-button>
              </li>
          </ul>
        </b-form>
      </div>
    </div>
    <!-- end: content block -->
  </div>
</template>

<script>

export default {
  name: 'UnSubscribe',
  components: {
  },
  data() {
    return {
      email: '',
      serviceCheck: false,
      err: {},
      emailFilled: false,
      subscribed: false,
    };
  },
  computed: {
    emailState() {
      if (!this.emailFilled) return null;
      this.verifyUnSubscribe();
      return !(Object.keys(this.err).length > 0 && Object.prototype.hasOwnProperty.call(this.err, 'email'));
    },
  },
  methods: {
    resetForm() {
      const _this = this;

      _this.email = '';
      _this.err = {};
      _this.emailFilled = false;
    }, // end resetForm
    verifyUnSubscribe() {
      const _this = this;

      // verify: email
      if (_this.$verify.isEmpty({
        val: _this.email,
      })) {
        _this.$set(_this.err, 'email', '請輸入 Email');
      } else if (!_this.$verify.isEmail({
        val: _this.email,
      })) {
        _this.$set(_this.err, 'email', '格式錯誤：請輸入 xxx@xxx.xxx');
      } else {
        _this.$delete(_this.err, 'email');
      }
    }, // end verifyUnSubscribe
    submitUnSubscribe() {
      const _this = this;
      _this.verifyUnSubscribe();

      if (Object.keys(_this.err).length === 0) {
        _this.$base.ajaxFunc({
          method: 'post',
          url: '/ssc/unsubscribe',
          data: {
            email: _this.email,
          },
          success() {
            _this.resetForm();

            _this.$store.dispatch('alert', {
              vm: _this,
              type: 'success',
              msg: '已取消訂閱！',
            });
          }, // end success
          vm: _this,
        }); // end call: ajaxFunc
      } // end if: no error
      this.emailFilled = true;
    }, // end submitUnSubscribe
  }, // end methods
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.service-block {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: $main-color-white;
  border-radius: 10px;

  @media all and (max-width: 1024px) {
      max-width: 920px;
  }

  li {
    margin-bottom: 1rem;

    &.list-type-none {
      list-style-type: none;
    }

    p {
      margin-bottom: 0;

      & + * {
        margin-top: 1rem;
      }
    } // end p
  } // end li

  ::v-deep .custom-control-label {
    cursor: pointer;
  }

  ::v-deep .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    color: $note-color;
    border-color: $note-color;
  }

  .subscribed {
    text-align: center;
    .description {
      font-size: 2rem;
      color: $green;
      font-weight: bold;
    }
    .text {
      color: $note-color;
    }
  }
} // end service block
</style>
