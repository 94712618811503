<template>
  <b-container class="login" fluid>
    <b-row>
      <b-col class="info-container" cols="12" md="6" lg="8">
        <div class="info-block">
          <div class="product-txt">
            <p>Scorpione</p>
          </div>

          <div class="slogan-txt">
            <p>{{ slogan }}</p>
          </div>
        </div>
      </b-col>
      <!-- end: info block -->

      <b-col class="form-block" cols="12" md="6" lg="4">

        <div class="img-block logo-block">
          <img src="../assets/imgs/scorpione.png" alt="ITRI" />
        </div>

        <b-form @submit="login" @reset="reset">
          <b-form-group
            id="input-group-account"
            label="帳號"
            label-for="input-account"
          >
            <b-form-input
              id="input-account"
              v-model="account"
              type="text"
              placeholder="Please enter your username."
              required
              :state="accountState"
              @keydown.enter.native="login"
            ></b-form-input>
            <b-form-invalid-feedback id="input-account">
              {{Object.keys(err).length > 0 && err.hasOwnProperty('account')? err.account: ''}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-pwd"
            label="密碼"
            label-for="input-pwd"
          >
            <b-form-input
              id="input-pwd"
              v-model="pwd"
              type="password"
              placeholder="Please enter your password."
              required
              :state="pwdState"
              @keydown.enter.native="login"
            ></b-form-input>
            <b-form-invalid-feedback id="input-pwd">
              {{Object.keys(err).length > 0 && err.hasOwnProperty('pwd')? err.pwd: ''}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button type="button" variant="primary" @click="login">登入</b-button>
        </b-form>
        <font size="4">如需註冊試用帳號，請與負責工程師聯繫<br />
                       連政杰  JackyLien@itri.org.tw<br />
                       劉哲豪  chehao.liu@itri.org.tw
        </font>
      </b-col>
      <!-- end: form block -->
    </b-row>
  </b-container>
</template>

<script>
// import Header from '@/layout/Header.vue';

export default {
  name: 'Login',
  // components: {
  //   Header,
  // },
  data() {
    return {
      slogan: '系統安全態勢分析工具',
      err: {},
      account: '',
      pwd: '',
      showVerification: false,
    };
  },
  computed: {
    accountState() {
      if (!this.showVerification) return null;
      this.verifyLogin();
      return !(Object.keys(this.err).length > 0 && Object.prototype.hasOwnProperty.call(this.err, 'account'));
    },
    pwdState() {
      if (!this.showVerification) return null;
      this.verifyLogin();
      return !(Object.keys(this.err).length > 0 && Object.prototype.hasOwnProperty.call(this.err, 'pwd'));
    },
  },
  methods: {

    reset() {
      const _this = this;

      _this.account = '';
      _this.pwd = '';
    }, // end reset

    verifyLogin() {
      const _this = this;

      if (_this.$verify.isEmpty({
        val: _this.account,
      })) {
        _this.$set(_this.err, 'account', '請輸入帳號');
      } else if (!_this.$verify.isAccount({
        val: _this.account,
      })) {
        _this.$set(_this.err, 'account', '格式錯誤：僅能包含英數字、_、@、.');
      } else {
        _this.$delete(_this.err, 'account');
      }

      if (_this.$verify.isEmpty({
        val: _this.pwd,
      })) {
        _this.$set(_this.err, 'pwd', '請輸入密碼');
      } else {
        _this.$delete(_this.err, 'pwd');
      }
    }, // end verifyLogin

    login() {
      const _this = this;
      _this.verifyLogin();
      // console.warn('login err', _this.err);

      if (Object.keys(_this.err).length === 0) {
        _this.$base.ajaxFunc({
          method: 'post',
          url: '/login',
          data: {
            username: _this.account,
            password: _this.pwd,
          },
          success() {
            _this.$store.dispatch('userSignIn');
          },
          vm: _this,
        });
        _this.showVerification = false;
        // end call: ajaxFunc
      } else _this.showVerification = true;
      // end if: verification is approved
    }, // end login

  }, // end methods
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;

  > .row {
    align-items: center;
    height: 100%;
    margin: 0;
  } // end row

  .info-container {
    height: 100%;
    padding-left: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    background-color: $main-color;
    color: $main-color-white;

    > * {
      flex: 0 0 100%;
    }

    .product-txt {
      margin-bottom: 0;
      p {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
      }
    } // end product

    .slogan-txt {
      p {
        font-size: 3rem;
        margin: 0;
        font-weight: normal;
        line-height: 1.2em;

        @include pc-1024-width(){
          font-size: 3rem;
        }
      }
    } // end slogan
  } // end info container

  .form-block {
    margin-top: $margin-md;
    text-align: center;

    .logo-block {
      max-width: 100px;
      margin: 0 auto 10px;
    }

    &::v-deep label {
      text-align: left;
    }
    .invalid-feedback {
      text-align: left;
    }

    .btn {
      width: 80%;
      max-width: 250px;
    }
  } // end form block
} // end login

@media all and (max-width: 767px) {
  .login {
    > .row {
      align-items: flex-start;
      height: auto;
    }

    .info-container {
      height: 60px;

      .slogan-txt {
        display: none;
      }
    } // end info container
  } // end login
} // end <= 767
</style>
