<template>
  <div class="notfound">
    <div class="content-block">
      <div class="img-block">
        <img src="@/assets/imgs/404.png" alt="Page not found.">
      </div>

      <div class="txt">
        <p>PAGE NOT FOUND</p>
      </div>
    </div>
    <!-- end: content block -->
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  }, // end methods
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.img-block {
  width: 50%;
  min-width: 300px;
  max-width: 404px;
  margin: 0 auto 20px;
}
.txt p {
  font-size: 2rem;
  color: $dark-color-grey;
  font-weight: bold;
  text-align: center;
}
</style>
